import { render, staticRenderFns } from "./CommentsTitle.vue?vue&type=template&id=0120db3d&scoped=true&lang=pug&functional=true&"
import script from "./CommentsTitle.vue?vue&type=script&lang=ts&"
export * from "./CommentsTitle.vue?vue&type=script&lang=ts&"
import style0 from "./CommentsTitle.vue?vue&type=style&index=0&id=0120db3d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0120db3d",
  null
  
)

export default component.exports