// TODO: Добавить типы вместо any и ts-ignore
import Vue, { PropType } from 'vue';
import FilterSubmitButton from '~/components/filter/FilterSubmitButton.vue';
import FilterResetButton from '~/components/filter/FilterResetButton.vue';
import TheCheckbox from '~/components/filter/TheCheckbox/TheCheckbox.vue';
import TextInput from '~/components/filter/TextInput.vue';
import DropDownList from '~/components/filter/DropDownList/DropDownList.vue';
import {
  Listener,
  Menu,
  MenuItem,
  MenuTime,
  SelectedMenuItem,
  SelectedMenuTime,
} from '~/containers/FilterContainer/types';
type ItemList = MenuItem[] | MenuTime[] | SelectedMenuItem[] | SelectedMenuTime[]
export default Vue.extend({
  name: 'FilterItem',
  components: {
    FilterSubmitButton,
    FilterResetButton,
    TheCheckbox,
    TextInput,
    DropDownList,
  },
  props: {
    menuItem: {
      type: Object as PropType<Menu>,
      required: true,
    },
    openedMenu: {
      type: String as PropType<string>,
      required: true,
    },
    select: {
      type: Function as PropType<Function>,
      required: true,
    },
    setFalseIsFixedSubmitButton: {
      type: Function as PropType<Function>,
      required: true,
    },
    setTrueIsFixedSubmitButton: {
      type: Function as PropType<Function>,
      required: true,
    },
    resetSearchQuery: {
      type: Function as PropType<Function>,
      required: true,
    },
    isShowSearchBtn: {
      type: Boolean as PropType<Boolean>,
      required: true,
    },
  },
  data: () => ({
    isTablet: false,
    item_listeneres: [] as Listener[],
  }),
  computed: {
    transitionName (): string {
      return this.$store.state.filter.isAnimate && this.menuItem.animate ? 'flip-list-menu' : '';
    },
    isOpenedMenu (): boolean {
      return this.isTablet || (this.openedMenu === this.menuItem.name);
    },
    itemList (): ItemList | undefined {
      return this.isOpenedMenu ? this.menuItem.openedList : this.menuItem.selectedList;
    },
    itemTwiceFirst (): ItemList | undefined {
      return this.menuItem.openedListTwice?.listFirst;
    },
    itemTwiceLast (): ItemList | undefined {
      return this.menuItem.openedListTwice?.listLast;
    },

    isValid (): boolean {
      const {
        searchValue,
        notFound,
        menuItem: {
          search: {
            // @ts-ignore
            minValueLength,
          },
        },
      } = this;

      if (!searchValue) {
        return true;
      }

      return minValueLength && searchValue.length < minValueLength
        ? true
        : !notFound;
    },

    isShowNotFoundDescription (): boolean {
      const {
        searchValue,
        notFound,
        menuItem: {
          search: {
            // @ts-ignore
            minValueLength,
          },
        },
      } = this;

      if (!searchValue) {
        return false;
      }

      if (minValueLength) {
        return minValueLength <= searchValue.length
          ? notFound
          : false;
      }

      return notFound;
    },

    isSelectedItems (): boolean {
      return Boolean(this.menuItem.selectedList?.length);
    },

    classDropDownList (): Record<string, boolean> {
      return {
        'drop-down__selected': this.isSelectedItems,
      };
    },

    searchValue (): string | null {
      return this.menuItem.search?.value ?? null;
    },

    openedList (): MenuItem[] | MenuTime[] {
      return this.menuItem.openedList || [];
    },

    notFound (): boolean {
      return this.menuItem.search?.notFound ?? false;
    },
  },

  watch: {
    notFound (_newValue): void {
      this.scrollContainer();
    },

    searchValue (_newValue: string | null): void {
      this.scrollContainer();
    },
  },

  mounted () {
    this.addEventListeners();
  },
  updated () {
    this.addEventListeners();
  },
  beforeUpdate () {
    this.removeEventListeners();
  },
  destroyed () {
    this.removeEventListeners();
  },
  methods: {
    async scrollContainer () {
      // @ts-ignore
      const containerOnce = this.$refs.containerOnce?.$el as HTMLElement;

      if (!containerOnce) {
        return;
      }

      const {
        searchValue,
        menuItem: {
          search: {
            // @ts-ignore
            minValueLength,
          },
        },
      } = this;

      if (!searchValue || searchValue.length === 0 || (minValueLength && searchValue.length < minValueLength)) {
        containerOnce.scrollLeft = 0;
        return;
      }

      if (this.notFound) {
        containerOnce.scrollLeft = 0;
        return;
      }

      const lastSelectedItem = Array.from(containerOnce.querySelectorAll('.input__label--show')).slice(-1)[0] as HTMLElement;
      if (!lastSelectedItem) {
        return;
      }

      await this.$nextTick();

      containerOnce.scrollLeft = (lastSelectedItem.offsetWidth * 0.5) + lastSelectedItem.offsetLeft;
    },

    inputHandler (animateCb: Function, type: string, id: string, row?: string): void {
      const _opts = { type, id, row };
      const opts = this.isTablet
        ? {
          ..._opts,
          animateCb,
        }
        : _opts;
      this.select(opts);
    },
    startHandler (value: boolean, item: MenuItem | MenuTime) {
      item.isLockAnimation = value;
    },
    itemBinder (item: MenuItem | MenuTime) {
      return {
        isTablet: this.isTablet,
        type: this.menuItem.inputType,
        value: item.id,
        label: item.title,
        checked: !!item.selected,
        isLockAnimation: item.isLockAnimation,
        activatedHandler: item.activatedHandler,
      };
    },
    itemOn (item: MenuItem, row?: 'first' | 'last') {
      return {
        change: ($e: any) => this.inputHandler($e, this.menuItem.name, item.id, row),
        start: ($e: any) => this.startHandler($e, item),
      };
    },
    addEventListeners () {
      const handler = (e: any) => {
        this.isTablet = !!e.matches;
      };
      const x = window.matchMedia('(max-width: 1023px)');
      handler(x);
      x.addEventListener('change', handler);

      this.item_listeneres.push({
        el: x,
        handler,
      });
    },
    removeEventListeners () {
      this.item_listeneres.forEach(({ el, handler }) => el.removeEventListener('change', handler));
    },
  },
});
