import { render, staticRenderFns } from "./CommentList.vue?vue&type=template&id=0545c0f0&scoped=true&lang=pug&functional=true&"
var script = {}
import style0 from "./CommentList.vue?vue&type=style&index=0&id=0545c0f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "0545c0f0",
  null
  
)

export default component.exports