var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{ref:"el",staticClass:"input__label",class:{
    'no-transition': !_vm.isFilterAnimate,
    'input__label--current': _vm.isTransition && (_vm.type === 'checkbox'),
    'input__label--show': _vm.isAnimateColor && (_vm.type === 'checkbox'),
    'input__label--opacity': _vm.isAnimateOpacity && _vm.isTablet && (_vm.type === 'checkbox'),
  }},[_c('input',{staticClass:"input__item",class:( _obj = {}, _obj["input__item--checked"] = _vm.checked, _obj ),attrs:{"type":"checkbox"},domProps:{"value":_vm.value},on:{"change":_vm.handler}}),_vm._v(" "),_c('span',{staticClass:"input__icon"},[_vm._v(_vm._s(_vm.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }